<template>

<div @click="onClick">
    <div class="inline field text-left">
        <vs-divider position="left">
            효과 및 관련성
        </vs-divider>
            <div class="m-4"> {{rowData.comment || "-"}} </div>
        <vs-divider position="left">
            Reference
        </vs-divider>
        <div class="m-4"> {{rowData.ref || "-"}} </div>

    </div>
</div>
</template>

<script>
export default {
    props: {
        rowData: {
            type: Object,
            required: true
        },
        rowIndex: {
            type: Number
        }
    },
    methods: {
        onClick (event) {
            console.log('my-detail-row: on-click', event.target)
        }
    }
}
</script>