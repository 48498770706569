<template>
    <div id="app" style="text-align: center" data-app>
      <vs-navbar v-if="isTotalView" :collapse="false" v-model="active" class="fixed-top menu-static"
                 ref="parentSidebar"
                 text-color="rgba(255,255,255,.6)"
                 active-text-color="rgba(255,255,255,1)"
                 :color="`#8CC152`" >

        <div slot="title">
          <vs-navbar-title>
            <vs-row vs-justify="center">
              <vs-col  vs-justify="center" vs-align="center" vs-lg="6" vs-sm="6" vs-xs="6">
                <div style="margin-top: 6px">
                  <!--    <img src="../../public/img/aib_logo.png" height="50%" width="50%"/>-->
                </div>
              </vs-col>
            </vs-row>
          </vs-navbar-title>
        </div>

        <vs-navbar-item index="0">
          <router-link to="/home">Home</router-link>
        </vs-navbar-item>

        <vs-navbar-item index="1" class="mr-10">
          <router-link to="/users" class="pr-10">사용자 관리</router-link>
        </vs-navbar-item>

        <vs-navbar-item index="7">
          <a href="/v1/logout">로그아웃</a>
        </vs-navbar-item>

        <vs-navbar-item index="2">
        </vs-navbar-item>
        <vs-spacer></vs-spacer>
        <vs-button color-text="rgb(255, 255, 255)" color="rgba(255, 255, 255, 0.3)" type="flat" ></vs-button>
      </vs-navbar>
     <br>
      <br>
        <div id="table-wrapper" class="ui container ">
          <div>
            <h2><strong>Scoring Table</strong></h2>
          </div>
            <vuetable ref="vuetable"
                      api-url="/v1/scoringTable"
                      :fields="fields"
                      :sort-order="sortOrder"
                      :css="css.table"
                      pagination-path=""
                      detail-row-component="my-detail-row"
                      :pagination-data="onPaginationData"
                      @vuetable:cell-clicked="onCellClicked"
                      @vuetable:loading="onLoading"
                      @vuetable:loaded="onLoaded"
                      @vuetable:row-clicked="hTest"
            >
                <template slot="actions" slot-scope="props" class="center aligned" style="width: 110px;" v-if="showUserSetting">
                    <div class="table-button-container">
                        <button class="btn btn-warning btn-sm" @click="editRow(props)">
                            <span class="glyphicon glyphicon-pencil"></span> Edit</button>&nbsp;&nbsp;
                        <button class="btn btn-danger btn-sm" @click="deleteRow(props)">
                            <span class="glyphicon glyphicon-trash"></span> Delete</button>&nbsp;&nbsp;
                    </div>
                </template>

            </vuetable>
<!--            <vuetable-pagination ref="pagination"
                                 :css="css.pagination"
                                 @vuetable-pagination:change-page="onChangePage"
            ></vuetable-pagination>-->
        </div>


        <div class="centerx">

            <vs-popup :button-close-hidden="true" classContent="popup-example" color="orange" title="수정" :active.sync="popupActivo2">
                <vs-row vs-justify="center">
                    <vs-col  vs-justify="center" vs-align="center" vs-lg="5" vs-sm="5" vs-xs="12">
                        <div>
                            <vs-input disabled  class="inputx mt-3 vs-input-margin" label-placeholder="Phylum:" v-model="popupData.phylum"/>
                        </div>
                    </vs-col>
                    <vs-col vs-justify="center" vs-align="center" vs-lg="5" vs-sm="5" vs-xs="12">
                        <div>
                            <vs-input disabled  class="inputx mt-3 vs-input-margin" label-placeholder="Genus:" v-model="popupData.genus"/>
                        </div>
                    </vs-col>
                </vs-row>
                <vs-row vs-justify="center">
                    <vs-col  vs-justify="center" vs-align="center" vs-lg="5" vs-sm="5" vs-xs="12">
                        <div>
                            <vs-input disabled  class="inputx mt-3 vs-input-margin" label-placeholder="Species:" v-model="popupData.species"/>
                        </div>
                    </vs-col>
                    <vs-col vs-justify="center" vs-align="center" vs-lg="5" vs-sm="5" vs-xs="12">
                        <div>
                            <vs-input class="inputx mt-3 vs-input-margin" label-placeholder="장건강:" v-model="popupData.gut"/>
                        </div>
                    </vs-col>
                </vs-row>
                <vs-row vs-justify="center">
                    <vs-col  vs-justify="center" vs-align="center" vs-lg="5" vs-sm="5" vs-xs="12">
                        <div>
                            <vs-input class="inputx mt-3 vs-input-margin" label-placeholder="면역체계:" v-model="popupData.immunity"/>
                        </div>
                    </vs-col>
                    <vs-col vs-justify="center" vs-align="center" vs-lg="5" vs-sm="5" vs-xs="12">
                        <div>
                            <vs-input class="inputx mt-3 vs-input-margin" label-placeholder="비만당뇨:" v-model="popupData.obesity"/>
                        </div>
                    </vs-col>
                </vs-row>
                <vs-row vs-justify="center">
                    <vs-col  vs-justify="center" vs-align="center" vs-lg="5" vs-sm="5" vs-xs="12">
                        <div>
                            <vs-input class="inputx mt-3 vs-input-margin" label-placeholder="아토피:" v-model="popupData.atopy"/>
                        </div>
                    </vs-col>
                    <vs-col vs-justify="center" vs-align="center" vs-lg="5" vs-sm="5" vs-xs="12">
                        <div>
                            <vs-input class="inputx mt-3 vs-input-margin" label-placeholder="성인병:" v-model="popupData.adult_disease"/>
                        </div>
                    </vs-col>
                </vs-row>

                <vs-row vs-justify="center">
                    <vs-col   vs-lg="5" vs-sm="5" vs-xs="12">
                        <div>
                            <vs-input class="inputx mt-3 vs-input-margin" label-placeholder="여성질환:" v-model="popupData.female_disease"/>
                        </div>
                    </vs-col>
                    <vs-col   vs-lg="5" vs-sm="5" vs-xs="12">
                        <div>
                            <vs-input class="mt-3 vs-input-margin" label-placeholder="기타:" v-model="popupData.etc"/>
                        </div>
                    </vs-col>
                </vs-row>

                <vs-row vs-justify="center">
                    <vs-col  vs-justify="center" vs-align="center" vs-lg="10" vs-sm="10" vs-xs="10" >
                        <div>
                            <vs-textarea label="효과 및 관련성:" v-model="popupData.comment" />
                        </div>
                    </vs-col>
                </vs-row>


                <vs-row vs-justify="center">
                    <vs-col  vs-justify="center" vs-align="center" vs-lg="10" vs-sm="10" vs-xs="10" >
                        <div>
                            <vs-textarea label="Reference:" v-model="popupData.ref" />
                        </div>
                    </vs-col>
                </vs-row>

                <vs-row vs-justify="center">
                    <vs-col  vs-justify="center" vs-align="center" vs-lg="1" vs-sm="1" vs-xs="1" >
                        <div>
                            <vs-button @click="reqUpdate" color="primary">SAVE</vs-button>
                        </div>
                    </vs-col>
                </vs-row>


            </vs-popup>
        </div>
        <fab
                :position="'bottom-right'"
                :bg-color="'#0f4299'"
                :actions="fabActions"
                :main-icon="'menu'"
                @survey="mergeAge"
                @add="add"
                @download="download"
                @Genus="genusListSheet=(!genusListSheet)"
                @apply="apply"
                v-if="showUserSetting"
        >
<!--                @cache="cache"
                @alertMe="alert"-->
        </fab>

        <vs-popup :button-close-hidden="true" classContent="popup-example" color="orange" title="추가" :active.sync="addPopup">
            <vs-row vs-justify="center">
                <vs-col  vs-justify="center" vs-align="center" vs-lg="5" vs-sm="5" vs-xs="12">
                    <div>
                        <vs-input class="inputx mt-3 vs-input-margin" label-placeholder="Phylum:" v-model="addPopupData.phylum"/>
                    </div>
                </vs-col>
                <vs-col vs-justify="center" vs-align="center" vs-lg="5" vs-sm="5" vs-xs="12">
                    <div>
                        <vs-input class="inputx mt-3 vs-input-margin" label-placeholder="Genus:" v-model="addPopupData.genus"/>
                    </div>
                </vs-col>
            </vs-row>
            <vs-row vs-justify="center">
                <vs-col  vs-justify="center" vs-align="center" vs-lg="5" vs-sm="5" vs-xs="12">
                    <div>
                        <vs-input class="inputx mt-3 vs-input-margin" label-placeholder="Species:" v-model="addPopupData.species"/>
                    </div>
                </vs-col>
                <vs-col vs-justify="center" vs-align="center" vs-lg="5" vs-sm="5" vs-xs="12">
                    <div>
                        <vs-input class="inputx mt-3 vs-input-margin" label-placeholder="장건강:" v-model="addPopupData.gut"/>
                    </div>
                </vs-col>
            </vs-row>
            <vs-row vs-justify="center">
                <vs-col  vs-justify="center" vs-align="center" vs-lg="5" vs-sm="5" vs-xs="12">
                    <div>
                        <vs-input class="inputx mt-3 vs-input-margin" label-placeholder="장-면역:" v-model="addPopupData.immunity"/>
                    </div>
                </vs-col>
                <vs-col vs-justify="center" vs-align="center" vs-lg="5" vs-sm="5" vs-xs="12">
                    <div>
                        <vs-input class="inputx mt-3 vs-input-margin" label-placeholder="당뇨 예방:" v-model="addPopupData.obesity"/>
                    </div>
                </vs-col>
            </vs-row>
            <vs-row vs-justify="center">
                <vs-col  vs-justify="center" vs-align="center" vs-lg="5" vs-sm="5" vs-xs="12">
                    <div>
                        <vs-input class="inputx mt-3 vs-input-margin" label-placeholder="장-피부:" v-model="addPopupData.atopy"/>
                    </div>
                </vs-col>
                <vs-col vs-justify="center" vs-align="center" vs-lg="5" vs-sm="5" vs-xs="12">
                    <div>
                        <vs-input class="inputx mt-3 vs-input-margin" label-placeholder="장-간:" v-model="addPopupData.adult_disease"/>
                    </div>
                </vs-col>
            </vs-row>

            <vs-row vs-justify="center">
                <vs-col   vs-lg="5" vs-sm="5" vs-xs="12">
                    <div>
                        <vs-input class="inputx mt-3 vs-input-margin" label-placeholder="장-뇌:" v-model="addPopupData.female_disease"/>
                    </div>
                </vs-col>
                <vs-col   vs-lg="5" vs-sm="5" vs-xs="12">
                    <div>
                        <vs-input class="mt-3 vs-input-margin" label-placeholder="기타:" v-model="addPopupData.etc"/>
                    </div>
                </vs-col>
            </vs-row>

            <vs-row vs-justify="center">
                <vs-col  vs-justify="center" vs-align="center" vs-lg="10" vs-sm="10" vs-xs="10" >
                    <div>
                        <vs-textarea label="효과 및 관련성:" v-model="addPopupData.comment" />
                    </div>
                </vs-col>
            </vs-row>


            <vs-row vs-justify="center">
                <vs-col  vs-justify="center" vs-align="center" vs-lg="10" vs-sm="10" vs-xs="10" >
                    <div>
                        <vs-textarea label="Reference:" v-model="addPopupData.ref" />
                    </div>
                </vs-col>
            </vs-row>

            <vs-row vs-justify="center">
                <vs-col  vs-justify="center" vs-align="center" vs-lg="1" vs-sm="1" vs-xs="1" >
                    <div>
                        <vs-button @click="insert" color="primary">SAVE</vs-button>
                    </div>
                </vs-col>
            </vs-row>


        </vs-popup>

        <download-excel ref="excel"

                        :fields="json_fields"
                        name="scoringTable.xls"
        >
            &nbsp;
                    </download-excel>


      <!-- Genus -->
      <v-row justify="center">
        <v-dialog v-model="genusListSheet" width="1200px">

          <v-card>
            <v-card-title>
              <span class="headline "><strong>Genus Level</strong></span>
            </v-card-title>
            <template>
              <v-card>
                <v-card-title>
                <!--  Genus Level-->
                  <v-spacer></v-spacer>
                </v-card-title>
                <v-data-table
                    items-per-page="-1"
                    :headers="genusHeaders"
                    :items="genusList"
                ></v-data-table>
              </v-card>
            </template>
<!--            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="dialog = false">Disagree</v-btn>
              <v-btn color="green darken-1" text @click="dialog = false">Agree</v-btn>
            </v-card-actions>-->
          </v-card>
        </v-dialog>
      </v-row>
    </div>

</template>

<script>

import Vue from 'vue'
import Vuetable from 'vuetable-2'
import DetailRow from './DetailRow'
import axios from 'axios'
import fab from 'vue-fab'
import downloadExcel from './JsonExcel.vue'

Vue.component('my-detail-row', DetailRow)

export default {

  components : {
    Vuetable,
    fab,
    downloadExcel
  },

  name: "ScoringTable",
  props: {
    isTotalView: {
      type: Boolean,
      default: () => { return true }
    }
  },
  data() {
      return {
        isApply : false,
        genusSearch : '',
        genusHeaders: [
          { text: 'Phylum', value: 'phylum' },
          { text: 'Genus', value: 'genus' },
          { text: '장 건강', value: 'gut' },
          { text: '장-면역', value: 'immunity' },
          { text: '당뇨 예방', value: 'obesity' },
          { text: '장-피부', value: 'atopy' },
          { text: '장-간', value: 'adult_disease' },
          { text: '장-뇌', value: 'female_disease' },
          { text: '기타', value: 'etc' },
        ],
        genusList: [],
          genusListSheet : false, //Genus 상세 리스트 (modal)
          showUserSetting : false,
          json_fields: {
              'Phylum': 'phylum',
              'Genus': 'genus',
              'species': 'species',
              '장 건강': 'gut',
              '장-면역': 'immunity',
              '당뇨 예방': 'obesity',
              '장-피부': 'atopy',
              '장-간': 'adult_disease',
              '장-뇌': 'female_disease',
              '기타': 'etc',
              'comment': 'comment',
              'reference': 'ref'
          },
          json_data: [],
          json_meta: [
              [
                  {
                      'key': 'charset',
                      'value': 'utf-8'
                  }
              ]
          ],
          fabActions: [
            {
              name: 'survey',
              tooltip: "설문조사동기화",
              icon: 'social_distance'
            },
            {
              name: 'apply',
              tooltip: "점수 적용",
              icon: 'sync'
            },
            {
              name: 'download',
              tooltip: "Excel 다운로드",
              icon: 'save_alt'
            },
            {
              name: 'add',
              tooltip: "추가",
              icon: 'add'
            },
            {
              name: 'Genus',
              tooltip: "Genus Level",
              icon : 'unfold_less',
            }
          ],
          value1:'',
          value2:'',
          popupActivo2:false,
          addPopup: false,
          addPopupData : {},
          editRowData : {},
          popupData : {},
          fields: [
              {
                  name: 'phylum',
                  title: '<span class="orange glyphicon glyphicon-user"></span> Phylum',
                  width: '12%'
              },
              {
                  name: 'genus',
                  title: '<span class="orange glyphicon glyphicon-user"></span> Genus',
                  sortField: 'genus',
                  width: '13%'
              },
              {
                  name: 'species',
                  title: 'Species',
                  sortField: 'species',
                  width: '13%'
              },
              {
                  name: 'gut',
                  title: '장 건강',
                  sortField: 'gut',
                  width: '8%'
              },
              {
                  name: 'immunity',
                  title: '장-면역',
                  sortField: 'immunity',
                  width: '8%'
              },
              {
                  name: 'obesity',
                  title: '당뇨 예방',
                  sortField: 'obesity',
                  width: '8%'
              },
              {
                  name: 'atopy',
                  title: '장-피부',
                  sortField: 'atopy',
                  width: '8%'
              },
              {
                  name: 'adult_disease',
                  title: '장-간',
                  sortField: 'adult_disease',
                  width: '8%'

              },
              {
                  name: 'female_disease',
                  title: '장-뇌',
                  sortField: 'female_disease',
                  width: '8%'
              },
              {
                  name: 'etc',
                  title: '기타',
                  sortField: 'etc',
                  width: '8%'
              },
              {
              name: 'actions',
              title: 'Actions',
              titleClass: 'center aligned',
              dataClass: 'center aligned',
              width: '12%'
            }

          ],
          sortOrder: [
              {field: 'species', direction: 'asc'}
          ],
          css: {
              table: {
                  tableClass: 'table table-striped table-bordered table-hovered',
                  loadingClass: 'loading',
                  ascendingIcon: 'glyphicon glyphicon-chevron-up',
                  descendingIcon: 'glyphicon glyphicon-chevron-down',
                  handleIcon: 'glyphicon glyphicon-menu-hamburger',
              },
              pagination: {
                  infoClass: 'pull-left',
                  wrapperClass: 'vuetable-pagination pull-right',
                  activeClass: 'btn-primary',
                  disabledClass: 'disabled',
                  pageClass: 'btn btn-border',
                  linkClass: 'btn btn-border',
                  icons: {
                      first: '',
                      prev: '',
                      next: '',
                      last: '',
                  },
              }
          }
      }
  },
  mounted () {
    //let el = document.getElementById("_gut");
    //el.addEventListener("click", this.hTest , false);
  },
  computed:{
      userLevel (){
        return this.$store.state.user.level;
      }
  },
    methods: {
        hTest (p) {
          console.log(p)
          //this.genusListSheet = true;
        },
        onPaginationData (paginationData) {
            this.$refs.pagination.setPaginationData(paginationData)
        },
        onChangePage (page) {
            this.$refs.vuetable.changePage(page)
        },
        editRow (data) {
            this.editRowData = data.rowData;
            this.popupData = {...this.editRowData}; //성능을 위해 객체 복사 (양방향 바인딩 끊기)
            this.popupActivo2 = true; // 팝업 오픈
        },
        deleteRow (data) {
            this.$vs.dialog({
                type:'confirm',
                color: 'danger',
                title: `주의!!!`,
                text: `${data.rowData.phylum}, ${data.rowData.genus}, ${data.rowData.species} 의 데이터를 정말 삭제 하시겠습니까? `,
                accept:() => {
                    this.$vs.loading();

                    axios.delete(`/v1/scoringTable/${data.rowData.phylum || '' }/${data.rowData.genus || '' }/${data.rowData.species || '' }`, this.popupData).then(res => {
                        console.log(res)
                        if(res.data.code === 200){
                            this.isApply = true;
                            this.$refs.vuetable.reload();
                            this.notify("성공", "삭제 되었습니다.", "success");
                            this.getGenusList();
                            return ;
                        }
                        this.notify("요청 실패", `${res.data.code} Error`, 'danger');

                    }).catch( (e) => {
                        this.notify("처리 실패", `관리자에게 문의 하세요.`, "danger");
                        console.error(e);
                    })
                }
            })

        },
        notify(title, msg, color="danger"){
            this.$vs.notify({
                title: title,
                text: msg,
                color: color
            })
            this.$vs.loading.close()
        },
        insert() {
          console.log(this.addPopupData)
            if(this.addPopupData.phylum.length < 0){
                this.notify("Phylum", "필수 값 입니다.");
                return false;
            }

            if(this.addPopupData.genus.length < 0){
                this.notify("Genus", "필수 값 입니다.");
                return false;
            }

            if(this.addPopupData.species.length < 0){
                this.notify("species", "필수 값 입니다.");
                return false;
            }

            if( Number.isNaN(Number.parseFloat(this.addPopupData.gut)) ){
                this.notify("장건강", "실수형만 허용합니다.");
                return false;
            }

            if( Number.isNaN(Number.parseFloat(this.addPopupData.immunity)) ){
                this.notify("면역체계", "실수형만 허용합니다.");
                return false;
            }

            if( Number.isNaN(Number.parseFloat(this.addPopupData.obesity)) ){
                this.notify("비만당뇨", "실수형만 허용합니다.");
                return false;
            }

            if( Number.isNaN(Number.parseFloat(this.addPopupData.atopy)) ){
                this.notify("아토피", "실수형만 허용합니다.");
                return false;
            }

            if( Number.isNaN(Number.parseFloat(this.addPopupData.adult_disease)) ){
                this.notify("성인병", "실수형만 허용합니다.");
                return false;
            }

            if( Number.isNaN(Number.parseFloat(this.addPopupData.female_disease)) ){
                this.notify("여성질환", "실수형만 허용합니다.");
                return false;
            }

            if( Number.isNaN(Number.parseFloat(this.addPopupData.etc)) ){
                this.notify("기타", "실수형만 허용합니다.");
                return false;
            }

            this.$vs.loading();

            axios.post(`/v1/scoringTable/add`, this.addPopupData).then(res => {
                console.log(res)
                if(res.data.code === 200){
                    this.isApply = true;
                    this.$refs.vuetable.reload();
                    this.$vs.loading.close()
                    this.notify("성공", "처리 완료", "success");
                    this.addPopup = false;

                    this.getGenusList();

                  return ;
                }
                this.notify("요청 실패", `${res.data.code} Error`, 'danger');

            }).catch( e => {
                this.notify("처리 실패", `관리자에게 문의 하세요.`, "danger");
                console.error(e);
            })
        },
        //TODO 코드 정리 필요
        reqUpdate () {
            if(this.popupData.phylum.length < 0){
                this.notify("Phylum", "필수 값 입니다.");
                return false;
            }

/*            if(editRowData.genus.length < 0){
                this.notify("Genus", "필수 값 입니다.");
                return false;
            }*/

            if(this.popupData.species.length < 0){
                this.notify("species", "필수 값 입니다.");
                return false;
            }

            if( Number.isNaN(Number.parseFloat(this.popupData.gut)) ){
                this.notify("장건강", "실수형만 허용합니다.");
                return false;
            }

            if( Number.isNaN(Number.parseFloat(this.popupData.immunity)) ){
                this.notify("면역체계", "실수형만 허용합니다.");
                return false;
            }

            if( Number.isNaN(Number.parseFloat(this.popupData.obesity)) ){
                this.notify("비만당뇨", "실수형만 허용합니다.");
                return false;
            }

            if( Number.isNaN(Number.parseFloat(this.popupData.atopy)) ){
                this.notify("아토피", "실수형만 허용합니다.");
                return false;
            }

            if( Number.isNaN(Number.parseFloat(this.popupData.adult_disease)) ){
                this.notify("성인병", "실수형만 허용합니다.");
                return false;
            }

            if( Number.isNaN(Number.parseFloat(this.popupData.female_disease)) ){
                this.notify("여성질환", "실수형만 허용합니다.");
             return false;
            }

            if( Number.isNaN(Number.parseFloat(this.popupData.etc)) ){
                this.notify("기타", "실수형만 허용합니다.");
                return false;
            }

            this.$vs.loading();

            axios.post(`/v1/scoringTable`, this.popupData).then(res => {
                console.log(res)
                if(res.data.code === 200){
                    this.isApply = true;
                    this.$vs.loading.close()
                    this.notify("성공", "처리 완료", "success");
                    this.popupActivo2=false;

                    //Bind 데이터 변경
                    Object.keys(this.popupData).reduce( (acc, key)=>{
                        this.editRowData[key] = this.popupData[key];
                    }, {});

                    this.getGenusList();
                    return ;
                }
                this.notify("요청 실패", `${res.data.code} Error`, 'danger');

            }).catch(e => {
                this.notify("처리 실패", `관리자에게 문의 하세요.`, "danger");
                console.error(e);
            })

        },
        onLoading () {
            console.log('loading... show your spinner here')
        },
        onLoaded () {
            this.$refs.vuetable.toggleDetailRow(1)
            console.log('loaded! .. hide your spinner here')
        },
        onCellClicked (data) {
          this.$refs.vuetable.toggleDetailRow(data.data.id)
        },
        add()  {
            this.addPopup = true;
        },
        async mergeAge() {
          try {
            await axios.get(`/v1/migration/age`)
            this.notify("성공", "설문조사 동기화 완료", "success");
          } catch (e) {
            this.notify("실패", "설문조사 동기화 실패", "danger");
          }
        },
      async apply() {

        this.$vs.loading();

        axios.get(`/v1/scoringTable/apply`).then(res => {
          if(res.data.code === 200){
            this.$vs.loading.close()
            this.notify("성공", "처리 완료", "success");
            return ;
          }
          this.notify("요청 실패", `${res.data.code} Error`, 'danger');

        }).catch( e => {
          this.notify("처리 실패", `관리자에게 문의 하세요.`, "danger");
          console.error(e);
        })
      },
      async download() {

        this.$vs.loading();

        axios.get(`/v1/scoringTable`).then(res => {
                if(res.data.code === 200){
                    this.$vs.loading.close()
                    this.notify("성공", "처리 완료", "success");
                    //this.json_data = res.data.data;
                    this.$refs.excel.data = res.data.data;

                    this.$refs.excel.generate();
                    return ;
                }
                this.notify("요청 실패", `${res.data.code} Error`, 'danger');

            }).catch( e => {
                this.notify("처리 실패", `관리자에게 문의 하세요.`, "danger");
                console.error(e);
            })
        },
        async getGenusList () {
          axios.get(`/v1/scoringTable/genus`).then(res => {
            if(res.data.code === 200){

              this.genusList = res.data.data;
              return ;
            }
          }).catch(e => {
            this.notify("처리 실패", `관리자에게 문의 하세요.`, "danger");
            console.error(e);
          })
        }
  },
  created () {
    if(this.$store.state.user.level === 0){
      this.showUserSetting = true;
      this.getGenusList();
    }

  },
  beforeRouteLeave (to, from, next) {
    if(this.isApply) {
      this.notify("주의", `수정 후 점수는 적용 되지 않는 상태 입니다. 오른쪽 하단 메뉴에서 적용 버튼 눌러주세요.`, "danger");
      next(false);
      return false;
    }
    next();
  }

}
</script>


<style>
    #app {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2c3e50;
    }

    table.vuetable {
        width: 1200px;
        table-layout: fixed;
        font-size: 15px !important;
        color : black;
    }

    .vs-popup--title{
        width: 100%;
        color : white;
        font-weight: 900;
        background-color: orange;
        position: relative;
        overflow: hidden;
        border-radius: 5px 5px 0 0;
    }

    .vs-popup--title h3 {
        transition: all .23s ease .1s;
        font-weight: 900;
        padding: 8px;
        padding-left: 12px;
        padding-top: 12px;
        font-size: 1.2rem;
    }

    .vs-con-textarea h4 {
        font-size: 0.9rem;
        color : gray;
        padding: 8px;
        padding-bottom: 0;
        cursor: default;
        -webkit-transition: all .25s ease;
        transition: all .25s ease;
        border-radius: 5px 5px 0 0;
    }

    .vs-placeholder-label {
        font-size: 15px !important;
        font-weight: 700;
    }

    .orange.glyphicon {
        color: orange;
    }

    .vs-input-margin {
        margin-bottom: 30px;

    }

    th.sortable {
        color: #000000;
    }

</style>